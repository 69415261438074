
import { supabase } from "../supabase";
import { user } from "@/store/useUser";

import { ref, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useToast, POSITION } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    const router = useRouter();

    const password = ref("");
    const processing = ref(false);

    const handlePasswordReset = async () => {
      processing.value = true;
      toast.clear();
      if (!password.value) {
        toast.error("Password required.", {
          position: POSITION.TOP_CENTER,
          timeout: false,
        });
      } else {
        const { error } = await supabase.auth.api.updateUser(user.token, {
          password: password.value,
        });
        if (error) {
          toast.error(error.message, {
            position: POSITION.TOP_CENTER,
            timeout: false,
          });
        } else {
          toast.success("Password Changed!", {
            position: POSITION.TOP_CENTER,
          });

          const sleep = (milliseconds: number) => {
            return new Promise((resolve) => setTimeout(resolve, milliseconds));
          };
          sleep(500).then(() => {
            router.replace({ name: "Dashboard" });
          });
        }
      }
      processing.value = false;
    };

    return {
      processing,
      password,
      handlePasswordReset,
    };
  },
});
