
import { supabase } from "../supabase";

import { ref, defineComponent } from "vue";

import { useToast, POSITION } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();

    const email = ref("");
    const processing = ref(false);

    const handlePasswordReset = async () => {
      processing.value = true;
      toast.clear();
      if (!email.value) {
        toast.error("Email required.", {
          position: POSITION.TOP_CENTER,
          timeout: false,
        });
      } else {
        const { error } = await supabase.auth.api.resetPasswordForEmail(
          email.value,
          { redirectTo: window.location.origin + "/resetpassword" }
        );
        if (error) {
          toast.error(error.message, {
            position: POSITION.TOP_CENTER,
            timeout: false,
          });
        } else {
          toast.success("We have e-mailed your password reset link!", {
            position: POSITION.TOP_CENTER,
          });
        }
      }
      processing.value = false;
    };

    return {
      processing,
      email,
      handlePasswordReset,
    };
  },
});
