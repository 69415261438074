
import { RebotOrder, fetchRebotOrders } from "@/api";
import { defineComponent, ref, onMounted, watch, toRefs } from "vue";

export default defineComponent({
  props: {
    statusId: { type: Number, required: true },
  },
  setup(props) {
    const { statusId } = toRefs(props);

    const loading = ref(false);
    const rebotOrderList = ref<RebotOrder[]>([]);

    const getRebotOrderList = async () => {
      loading.value = true;
      const data = await fetchRebotOrders(statusId.value);
      if (data) rebotOrderList.value = data ?? [];
      loading.value = false;
    };

    onMounted(getRebotOrderList);
    watch(statusId, getRebotOrderList);

    return { rebotOrderList };
  },
});
