import forge from "node-forge";

const publicKey = forge.pki.publicKeyFromPem(
  forge.util.decode64(process.env.VUE_APP_API_SECRET_KEY_PUB || "")
);

export function encrypt(data: string): string {
  return publicKey.encrypt(data, "RSA-OAEP", {
    md: forge.md.sha1.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });
}

export function encrypt64(data: string): string {
  return forge.util.encode64(encrypt(data));
}
