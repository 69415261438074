
import { ref, defineComponent } from "vue";
import { supabase } from "../supabase";

import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();

    const email = ref("");
    const password = ref("");
    const processing = ref(false);

    const handleSignup = async () => {
      processing.value = true;
      toast.clear();

      if (!email.value) {
        toast.error("Email required.", { timeout: false });
        processing.value = false;
        return;
      }

      if (!password.value) {
        toast.error("Password required.", { timeout: false });
        processing.value = false;
        return;
      }

      const { error } = await supabase.auth.signUp({
        email: email.value,
        password: password.value,
      });
      if (error) {
        toast.error(error.message, { timeout: false });
        processing.value = false;
        return;
      }
      toast.info("Please check your email for account activation link.", {
        timeout: false,
      });
      processing.value = false;
    };

    return { processing, email, password, handleSignup };
  },
});
