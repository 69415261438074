
import { defineComponent } from "vue";

import NavBar from "@/components/NavBar.vue";
import BinanceHeader from "@/components/BinanceHeader.vue";

export default defineComponent({
  name: "Binance",
  components: {
    NavBar,
    BinanceHeader,
  },
});
