import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 md:grid-cols-8 gap-4" }
const _hoisted_2 = { class: "md:col-span-2" }
const _hoisted_3 = { class: "md:col-span-4" }
const _hoisted_4 = { class: "md:col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExchangeSettings = _resolveComponent("ExchangeSettings")!
  const _component_ExchangePortfolioSettings = _resolveComponent("ExchangePortfolioSettings")!
  const _component_ExchangeKeySettings = _resolveComponent("ExchangeKeySettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ExchangeSettings, { exchangeId: "binance" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ExchangePortfolioSettings, { exchangeId: "binance" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ExchangeKeySettings, { exchangeId: "binance" })
    ])
  ]))
}