import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-white shadow-sm" }
const _hoisted_2 = { class: "mx-auto py-4 px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "-my-px ml-6 flex space-x-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.name,
            to: item,
            class: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
            "active-class": "border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.visibleName), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ])
  ]))
}