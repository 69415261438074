import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./tailwind.css";

import { createPinia } from "pinia";

import { initAuth } from "./store/useUser";

import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  newestOnTop: true,
};

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(Toast, options);
app.mount("#app");

initAuth();
