
import { defineComponent, ref, onMounted, watch } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import { user } from "@/store/useUser";
import {
  fetchRebotSettings,
  setRebotEnabled,
  setRebotTresholdLimitDay,
  setRebotTresholdLimitRatio,
} from "@/api";

export default defineComponent({
  props: {
    exchangeId: { type: String, required: true },
  },
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup(props) {
    const rebotEnabled = ref(false);
    const treshold_limit_ratio = ref(0);
    const treshold_limit_day = ref(0);
    const isLoading = ref(false);

    const getRebotSettings = async () => {
      isLoading.value = true;
      const data = await fetchRebotSettings(user.id, props.exchangeId);
      if (data) {
        rebotEnabled.value = data[0].rebot_enabled;
        treshold_limit_ratio.value = data[0].treshold_limit_ratio;
        treshold_limit_day.value = data[0].treshold_limit_day;
      }
      isLoading.value = false;

      watch(rebotEnabled, () => {
        console.log(rebotEnabled.value);
        saveRebotEnabled();
      });
    };

    const saveRebotEnabled = async () => {
      const result = await setRebotEnabled(
        user.id,
        props.exchangeId,
        rebotEnabled.value
      );
      if (result) {
        // TODO alert message.
      }
    };

    const saveRebotTresholdLimitDay = async () => {
      const result = await setRebotTresholdLimitDay(
        user.id,
        props.exchangeId,
        treshold_limit_day.value
      );
      if (result) {
        // TODO alert message.
      }
    };

    const saveRebotTresholdLimitRatio = async () => {
      const result = await setRebotTresholdLimitRatio(
        user.id,
        props.exchangeId,
        treshold_limit_ratio.value
      );
      if (result) {
        // TODO alert message.
      }
    };

    onMounted(getRebotSettings);

    return {
      isLoading,
      rebotEnabled,
      treshold_limit_ratio,
      treshold_limit_day,
      saveRebotEnabled,
      saveRebotTresholdLimitRatio,
      saveRebotTresholdLimitDay,
    };
  },
});
