
import { defineComponent, ref } from "vue";
import { user } from "@/store/useUser";
import { updateExchangeKeys } from "@/api";

import { RefreshIcon } from "@heroicons/vue/outline";

export default defineComponent({
  props: {
    exchangeId: { type: String, required: true },
  },
  setup(props) {
    const apiKey = ref("");
    const apiSecret = ref("");
    const isLoading = ref(false);

    const saveApiKeysToDB = async () => {
      isLoading.value = true;
      const result = await updateExchangeKeys(
        user.id,
        props.exchangeId,
        apiKey.value,
        apiSecret.value
      );
      if (result) {
        //console.log("updated");
        // TODO alert message.
      }
      isLoading.value = false;
      apiKey.value = "";
      apiSecret.value = "";
    };

    return {
      isLoading,
      apiKey,
      apiSecret,
      saveApiKeysToDB,
      RefreshIcon,
    };
  },
});
