
import { defineComponent } from "vue";

import RebotStatusList from "@/components/RebotStatusList.vue";

export default defineComponent({
  name: "Binance",
  components: {
    RebotStatusList,
  },
});
