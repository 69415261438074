import { reactive, computed } from "vue";

import md5 from "crypto-js/md5";

import { supabase } from "../supabase";
//import { setToken } from "../api";

interface User {
  id: string;
  email: string;
  emailMd5: string;
  token: string;
}

export const user = reactive<User>({
  id: "",
  email: "",
  emailMd5: "",
  token: "",
});

export function setUser(id: string, email: string, token: string): void {
  user.id = id;
  user.email = email;
  user.emailMd5 = md5(user.email).toString();
  user.token = token;
  //setToken(user.token, "Bearer");
  return;
}

export function initAuth(): void {
  const authSession = supabase.auth.session();
  setUser(
    authSession?.user?.id ?? "",
    authSession?.user?.email ?? "",
    authSession?.access_token ?? ""
  );
  supabase.auth.onAuthStateChange((event, session) => {
    setUser(
      session?.user?.id ?? "",
      session?.user?.email ?? "",
      session?.access_token ?? ""
    );
  });
  return;
}

export function logout(): void {
  supabase.auth.signOut();
  return;
}

export const isLoggedIn = computed(() => !!user.id);
