
import { useRouter, useRoute } from "vue-router";
import type { RouteLocationRaw } from "vue-router";
import { defineComponent, ref } from "vue";
import { isLoggedIn } from "../store/useUser";
import { supabase } from "../supabase";

import { useToast } from "vue-toastification";

export default defineComponent({
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const email = ref("");
    const password = ref("");
    const processing = ref(false);

    async function waitForOnAuthStateChange() {
      let waitForLogin = true;
      while (waitForLogin) {
        if (isLoggedIn.value) {
          waitForLogin = false;
          return;
        }

        await null; // prevents app from hanging
      }
    }

    const handleLogin = async () => {
      processing.value = true;
      toast.clear();

      if (!email.value) {
        toast.error("Email required.", { timeout: false });
        processing.value = false;
        return;
      }

      if (!password.value) {
        toast.error("Password required.", { timeout: false });
        processing.value = false;
        return;
      }

      const { error } = await supabase.auth.signIn({
        email: email.value,
        password: password.value,
      });

      if (error) {
        toast.error(error.message);
        processing.value = false;
        return;
      }

      toast.info("Signed In. Redirecting...");

      const redirect: RouteLocationRaw = route.query.redirect?.toString() || "";

      waitForOnAuthStateChange();
      processing.value = false;
      toast.clear();
      router.replace(redirect);
    };

    return { handleLogin, email, password, processing };
  },
});
