
import { defineComponent } from "vue";
import ExchangeSettings from "@/components/ExchangeSettings.vue";
import ExchangeKeySettings from "@/components/ExchangeKeySettings.vue";
import ExchangePortfolioSettings from "@/components/ExchangePortfolioSettings.vue";

export default defineComponent({
  name: "Binanc",
  components: {
    ExchangeSettings,
    ExchangeKeySettings,
    ExchangePortfolioSettings,
  },
});
