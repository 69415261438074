import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Settings from "../views/Settings.vue";
import Binance from "../views/Binance.vue";
import BinanceHistory from "../views/BinanceHistory.vue";
import BinanceSettings from "../views/BinanceSettings.vue";
import Signin from "../views/Signin.vue";
import Signup from "../views/Signup.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import { isLoggedIn } from "../store/useUser";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: "/binance",
    name: "Binance",
    component: Binance,
    meta: { requiresAuth: true },
    children: [
      {
        path: "history",
        name: "BinanceHistory",
        component: BinanceHistory,
        meta: { requiresAuth: true },
      },
      {
        path: "settings",
        name: "BinanceSettings",
        component: BinanceSettings,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: { requiresAuth: false },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { requiresAuth: false },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLoggedIn.value) {
      next();
    } else {
      next({
        path: "/signin",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
