
import { supabase } from "../supabase";
import NavBar from "@/components/NavBar.vue";

import { defineComponent, ref } from "vue";
import { useToast, POSITION } from "vue-toastification";

export default defineComponent({
  name: "Settings",
  components: {
    NavBar,
  },
  setup() {
    const toast = useToast();

    const password = ref("");
    const passwordConfirm = ref("");

    const processing = ref(false);

    const handlePasswordUpdate = async () => {
      let hasError = false;
      processing.value = true;
      if (!password.value) {
        toast.error("Password required.", {
          position: POSITION.TOP_CENTER,
        });
        hasError = true;
      }

      if (!passwordConfirm.value) {
        toast.error("Please re-enter password.", {
          position: POSITION.TOP_CENTER,
        });
        hasError = true;
      }

      if (password.value != passwordConfirm.value) {
        toast.error("Passwords do not match", {
          position: POSITION.TOP_CENTER,
        });
        hasError = true;
      }

      if (hasError) {
        processing.value = false;
        return;
      }

      const { error } = await supabase.auth.update({
        password: password.value,
      });

      if (error) {
        toast.error(error.message, {
          position: POSITION.TOP_CENTER,
        });
      } else {
        toast.success("Password Changed!", {
          position: POSITION.TOP_CENTER,
        });
      }
      processing.value = false;
    };

    return {
      password,
      passwordConfirm,
      processing,
      handlePasswordUpdate,
    };
  },
});
