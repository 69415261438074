
import { RebotBalance, fetchRebotBalances } from "@/api";
import { defineComponent, ref, onMounted, watch, toRefs } from "vue";

export default defineComponent({
  props: {
    statusId: { type: Number, required: true },
    balanceType: { type: Number, required: true },
  },
  setup(props) {
    const { statusId, balanceType } = toRefs(props);

    const loading = ref(false);
    const rebotBalanceList = ref<RebotBalance[]>([]);

    const getRebotBalanceList = async () => {
      loading.value = true;
      const data = await fetchRebotBalances(statusId.value, balanceType.value);
      if (data) rebotBalanceList.value = data ?? [];
      loading.value = false;
    };

    onMounted(getRebotBalanceList);
    watch(statusId, getRebotBalanceList);

    const formatNumberPercent = (x: number) => {
      return (x * 100).toFixed(2) + "%";
    };

    return { rebotBalanceList, formatNumberPercent };
  },
});
