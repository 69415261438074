import { supabase } from "@/supabase";
import { encrypt64 } from "@/cryptosystem";

export interface RebotStatus {
  id: number;
  user_id: string;
  exchange_id: string;
  doc: Date;
  status: string;
  error_msg: string;
}

export interface RebotOrder {
  id: number;
  exchange_order_id: string;
  amount: number;
  cost: number;
  type: string;
  side: string;
  symbol: string;
  status_id: number;
}

export interface RebotBalance {
  id: number;
  status_id: number;
  currency: string;
  current_amount: number;
  current_value: number;
  current_ratio: number;
  target_amount: number;
  target_value: number;
  target_ratio: number;
  type: number;
}

export interface ExchangeCurrency {
  currency: string;
}

export interface CustomPlannerBlacklist {
  asset: string;
}

export interface CustomPlannerBlacklistInsertT {
  asset: string;
  user_id: string;
  exchange_id: string;
}

export interface CustomPlannerDetail {
  asset: string;
  target_allocation: number;
}

export interface CustomPlannerDetailInsertT {
  asset: string;
  target_allocation: number;
  user_id: string;
  exchange_id: string;
}

export interface RebotSettings {
  rebot_enabled: boolean;
  treshold_limit_ratio: number;
  treshold_limit_day: number;
}

export interface DailyRebotStatus {
  doc: Date;
  total_value: number;
  status: string;
}

export async function fetchRebotStatusList(
  user_id: string,
  exchange_id: string
): Promise<RebotStatus[] | null> {
  try {
    const { data, error } = await supabase
      .from<RebotStatus>("rebot_status")
      .select("*")
      .eq("user_id", user_id)
      .eq("exchange_id", exchange_id)
      .order("doc", { ascending: false });

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function fetchRebotOrders(
  status_id: number
): Promise<RebotOrder[] | null> {
  try {
    const { data, error } = await supabase
      .from<RebotOrder>("rebot_orders")
      .select("*")
      .eq("status_id", status_id)
      .order("id");
    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

// @param type 1:before, 2:after
export async function fetchRebotBalances(
  status_id: number,
  type: number
): Promise<RebotBalance[] | null> {
  try {
    const { data, error } = await supabase
      .from<RebotBalance>("rebot_balances")
      .select("*")
      .eq("status_id", status_id)
      .eq("type", type)
      .order("id");
    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function updateExchangeKeys(
  user_id: string,
  exchange_id: string,
  api_key: string,
  api_secret: string
): Promise<boolean> {
  try {
    const apiKeyEncrypted = encrypt64(api_key);
    const apiSecretEncrypted = encrypt64(api_secret);

    const { error } = await supabase.from("exchange_keys").upsert(
      {
        user_id,
        exchange_id,
        api_key: apiKeyEncrypted,
        api_secret: apiSecretEncrypted,
      },
      { returning: "minimal" }
    );
    if (error) throw error;
    return true;
  } catch (error) {
    return false;
  }
}

export async function fetchExchangeCurrency(
  exchange_id: string
): Promise<ExchangeCurrency[] | null> {
  try {
    const { data, error } = await supabase
      .from<ExchangeCurrency>("exchange_currency")
      .select("currency")
      .match({ exchange_id })
      .order("currency");

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function fetchCustomPlannerBlacklist(
  user_id: string,
  exchange_id: string
): Promise<CustomPlannerBlacklist[] | null> {
  try {
    const { data, error } = await supabase
      .from<CustomPlannerBlacklist>("custom_planner_blacklist")
      .select("asset")
      .match({ user_id, exchange_id });

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function setCustomPlannerBlacklist(
  user_id: string,
  exchange_id: string,
  ignorelist_array: CustomPlannerBlacklist[]
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from("custom_planner_blacklist")
      .delete()
      .match({ exchange_id, user_id });

    if (error) throw error;
  } catch (error) {
    return false;
  }

  try {
    if (ignorelist_array.length == 0) {
      return true;
    }

    const data_array: CustomPlannerBlacklistInsertT[] = [];
    for (const x of ignorelist_array) {
      data_array.push({
        user_id,
        exchange_id,
        asset: x.asset,
      });
    }

    const { error } = await supabase
      .from("custom_planner_blacklist")
      .insert(data_array);

    if (error) throw error;
  } catch (error) {
    return false;
  }

  return true;
}

export async function fetchCustomPlannerDetails(
  user_id: string,
  exchange_id: string
): Promise<CustomPlannerDetail[] | null> {
  try {
    const { data, error } = await supabase
      .from<CustomPlannerDetail>("custom_planner_details")
      .select("asset, target_allocation")
      .match({ user_id, exchange_id });

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function setCustomPlannerDetails(
  user_id: string,
  exchange_id: string,
  portfolio_array: CustomPlannerDetail[]
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from("custom_planner_details")
      .delete()
      .match({ exchange_id, user_id });

    if (error) throw error;
  } catch (error) {
    return false;
  }

  try {
    if (portfolio_array.length == 0) {
      return true;
    }

    const data_array: CustomPlannerDetailInsertT[] = [];
    for (const x of portfolio_array) {
      data_array.push({
        user_id,
        exchange_id,
        asset: x.asset,
        target_allocation: x.target_allocation,
      });
    }

    const { error } = await supabase
      .from("custom_planner_details")
      .insert(data_array);

    if (error) throw error;
  } catch (error) {
    return false;
  }

  return true;
}

export async function fetchRebotSettings(
  user_id: string,
  exchange_id: string
): Promise<RebotSettings[] | null> {
  try {
    const { data, error } = await supabase
      .from<RebotSettings>("exchanges")
      .select("rebot_enabled, treshold_limit_ratio, treshold_limit_day")
      .match({ user_id, id: exchange_id });

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}

export async function setRebotEnabled(
  user_id: string,
  exchange_id: string,
  value: boolean
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from("exchanges")
      .update({ rebot_enabled: value })
      .match({ id: exchange_id, user_id });
    if (error) throw error;
    return true;
  } catch (error) {
    return false;
  }
}

export async function setRebotTresholdLimitRatio(
  user_id: string,
  exchange_id: string,
  value: number
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from("exchanges")
      .update({ treshold_limit_ratio: value })
      .match({ id: exchange_id, user_id });
    if (error) throw error;
    return true;
  } catch (error) {
    return false;
  }
}

export async function setRebotTresholdLimitDay(
  user_id: string,
  exchange_id: string,
  value: number
): Promise<boolean> {
  try {
    const { error } = await supabase
      .from("exchanges")
      .update({ treshold_limit_day: value })
      .match({ id: exchange_id, user_id });
    if (error) throw error;
    return true;
  } catch (error) {
    return false;
  }
}

export async function fetchRebotStatusTotalValueBinance(
  user_id: string
): Promise<DailyRebotStatus[] | null> {
  try {
    const { data, error } = await supabase
      .from<DailyRebotStatus>("v_rebot_status_total_value_binance")
      .select("doc, total_value, status")
      .match({ user_id })
      .order("doc");

    if (error) throw error;

    return data;
  } catch (error) {
    return null;
  }
}
