
import { defineComponent, onMounted, ref, computed } from "vue";

import NavBar from "@/components/NavBar.vue";

import { user } from "@/store/useUser";

import { fetchRebotStatusTotalValueBinance } from "@/api";

import { LineChart, useLineChart } from "vue-chart-3";
import {
  Chart,
  ChartData,
  ChartOptions,
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
);

import moment from "moment";

export default defineComponent({
  name: "Home",
  components: {
    NavBar,
    LineChart,
  },
  setup() {
    const dataValues = ref<number[]>([]);
    const labelValues = ref<string[]>([]);

    const portfolioTotalAll = ref(0);
    const portfolioTotalBinance = ref(0);
    const lastRebalanceRelativeTime = ref("never");
    const lastCheckRelativeTime = ref("never");

    const fetchData = async () => {
      const binanceValues = await fetchRebotStatusTotalValueBinance(user.id);
      if (binanceValues) {
        const tmpLabelValues: string[] = [];
        const tmpdataValues: number[] = [];
        binanceValues.forEach(function (dailyValue) {
          tmpLabelValues.push(moment(dailyValue.doc).format("DD-MM-YYYY"));
          tmpdataValues.push(dailyValue.total_value);

          portfolioTotalBinance.value = dailyValue.total_value;

          lastCheckRelativeTime.value = moment(dailyValue.doc).fromNow();
          if (dailyValue.status === "REBALANCED") {
            lastRebalanceRelativeTime.value = moment(dailyValue.doc).fromNow();
          }
        });

        labelValues.value = tmpLabelValues;
        dataValues.value = tmpdataValues;
      }
      portfolioTotalAll.value = portfolioTotalBinance.value;
    };
    onMounted(fetchData);

    const testData = computed<ChartData<"line">>(() => ({
      labels: labelValues.value,
      datasets: [
        {
          label: "USDT Value",
          data: dataValues.value,
          fill: true,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    }));

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: testData,
      //options,
    });

    return {
      lineChartProps,
      lineChartRef,
      portfolioTotalAll,
      portfolioTotalBinance,
      lastCheckRelativeTime,
      lastRebalanceRelativeTime,
    };
  },
});
