
import { defineComponent, ref, onMounted, computed } from "vue";
import { user } from "@/store/useUser";

import { SearchIcon } from "@heroicons/vue/solid";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import {
  CustomPlannerDetail,
  fetchCustomPlannerDetails,
  fetchExchangeCurrency,
  setCustomPlannerDetails,
  CustomPlannerBlacklist,
  fetchCustomPlannerBlacklist,
  setCustomPlannerBlacklist,
} from "@/api";

interface PortfolioItem {
  asset: string;
  target_allocation: number;
  ignored: boolean;
}

export default defineComponent({
  props: {
    exchangeId: { type: String, required: true },
  },
  components: {
    SearchIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup(props) {
    const isLoading = ref(false);
    //const availableCurrencies = ref<ExchangeCurrency[]>([]);
    const customPlannerDetailsRef = ref<PortfolioItem[]>([]);
    const searchTextRef = ref("");
    const filterValuesRef = ref(false);
    const savingPortfolio = ref(false);

    const fetchData = async () => {
      isLoading.value = true;
      const exchangeCurrencies = await fetchExchangeCurrency(props.exchangeId);
      const customPlannerDetails = await fetchCustomPlannerDetails(
        user.id,
        props.exchangeId
      );

      const customPlannerBlacklist = await fetchCustomPlannerBlacklist(
        user.id,
        props.exchangeId
      );

      let result_map = new Map<string, PortfolioItem>();
      if (exchangeCurrencies) {
        result_map = exchangeCurrencies.reduce(function (map, obj) {
          map.set(obj.currency, {
            asset: obj.currency,
            target_allocation: 0,
            ignored: false,
          });
          return map;
        }, result_map);

        if (customPlannerDetails) {
          customPlannerDetails.forEach((element) => {
            let obj = result_map.get(element.asset);
            if (obj) obj.target_allocation = element.target_allocation;
          });
        }
        if (customPlannerBlacklist) {
          customPlannerBlacklist.forEach((element) => {
            let obj = result_map.get(element.asset);
            if (obj) obj.ignored = true;
          });
        }
      }
      const result_array = Array.from(result_map, ([asset, obj]) => ({
        asset,
        target_allocation: obj.target_allocation,
        ignored: obj.ignored,
      }));

      customPlannerDetailsRef.value = result_array;

      isLoading.value = false;
    };

    const savePortfolio = async () => {
      savingPortfolio.value = true;
      const dataPlanner: CustomPlannerDetail[] = [];
      const dataIgnoreList: CustomPlannerBlacklist[] = [];
      for (const x of customPlannerDetailsRef.value) {
        if (x.target_allocation > 0) {
          dataPlanner.push({
            asset: x.asset,
            target_allocation: x.target_allocation,
          });
        }
        if (x.ignored) {
          dataIgnoreList.push({ asset: x.asset });
        }
      }
      let result = await setCustomPlannerDetails(
        user.id,
        props.exchangeId,
        dataPlanner
      );
      if (result) {
        // TODO alert message
      }

      result = await setCustomPlannerBlacklist(
        user.id,
        props.exchangeId,
        dataIgnoreList
      );
      if (result) {
        // TODO alert message
      }

      savingPortfolio.value = false;
    };

    onMounted(fetchData);

    const matchingCustomPlannerDetails = computed(() => {
      return customPlannerDetailsRef.value.filter(
        (customPlannerDetail) =>
          (!filterValuesRef.value ||
            customPlannerDetail.target_allocation > 0 ||
            customPlannerDetail.ignored) &&
          customPlannerDetail.asset.includes(searchTextRef.value.toUpperCase())
      );
    });

    return {
      isLoading,
      matchingCustomPlannerDetails,
      searchTextRef,
      filterValuesRef,
      savingPortfolio,
      savePortfolio,
    };
  },
});
