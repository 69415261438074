
import { defineComponent, computed } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";

import { user, logout } from "../store/useUser";

const navigation = [
  { name: "Dashboard", visibleName: "Home" },
  { name: "Settings", visibleName: "Settings" },
  { name: "Binance", visibleName: "Binance" },
];

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
  },
  setup() {
    const avatarUrl = computed(() => {
      return `https://www.gravatar.com/avatar/${user.emailMd5}?d=identicon`;
    });

    return {
      user,
      navigation,
      avatarUrl,
      logout,
    };
  },
});
