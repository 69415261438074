
import { defineComponent } from "vue";
const navigation = [
  { name: "BinanceSettings", visibleName: "Settings" },
  { name: "BinanceHistory", visibleName: "History" },
];
export default defineComponent({
  setup() {
    return { navigation };
  },
});
